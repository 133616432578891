import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  button: {
    position: 'fixed',
    bottom: '35px',
    right: '30px',
    width: '2.5rem',
    height: '2.5rem',
    backgroundColor: theme.colors.orange[5],
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    cursor: 'pointer',
    transition: 'box-shadow 150ms ease-in-out, opacity 200ms ease-in-out',
    opacity: '0',
    pointerEvents: 'none',
    zIndex: 999,
    padding: '0.3rem',

    '&:hover': {
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px'
    }
  },

  active: {
    pointerEvents: 'auto',
    opacity: '1'
  }
}));
