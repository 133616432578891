import { useEffect, useState } from 'react';

import { useCookies } from 'react-cookie';

import { useStyles } from './EDRDollarsBanner.style';

import { Container } from '@/components/Container';
import { IconEdrLogoSmall, IconX } from '@/components/Icons';

interface EDRDollarsBannerProps {
  value: number;
}

export const EDRDollarsBanner = ({ value }: EDRDollarsBannerProps): React.ReactElement => {
  const { classes, cx } = useStyles();
  const [isClosed, setIsClosed] = useState(false);
  const [cookies, setCookie] = useCookies(['edrGlobalMessageIsHidden']);
  const [shouldDisplay, setShouldDisplay] = useState(false);

  const handleClose = (): void => {
    const now = new Date();
    const sevenDaysFromNow = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);

    setCookie('edrGlobalMessageIsHidden', 'true', {
      expires: sevenDaysFromNow,
      secure: true
    });
    setIsClosed(true);
  };

  useEffect(() => {
    if (isClosed || cookies.edrGlobalMessageIsHidden || value === 0) {
      setShouldDisplay(false);
    }
    else {
      setShouldDisplay(true);
    }
  }, [isClosed, cookies.edrGlobalMessageIsHidden, value]);

  return (
    <div className={cx(classes.bannerWrapper, shouldDisplay && classes.bannerWrapperActive)}>
      <Container className={classes.bannerContainer} size="lg">
        <p className={classes.text}>
          <IconEdrLogoSmall className={classes.icon} />
          Enjoy up to ${value} off your order by spending your Everyday Rewards dollars
        </p>
        <button
          className={classes.closeButton}
          onClick={handleClose}
          title="Close message"
          type="button"
        >
          <IconX size={12} color="#636366" />
        </button>
      </Container>
    </div>
  );
};
