import { useEffect } from 'react';

import { useCookies } from 'react-cookie';

import { promotionListTracking } from '@/lib/tracking/promotion';
import { pushToGtmDatalayer } from '@/lib/utils';
import { type CmsData } from '@/types/cms';

export const useHomePageTracking = (cmsData: CmsData): {
  etmcDatalayer: Array<Record<string, any>>;
} => {
  const [cookies] = useCookies(['_sfe']);

  const etmcDatalayer: Array<Record<string, any>> = [
    ['setOrgId', '110005440'],
    ['trackPageView']
  ];

  if (cookies._sfe) {
    etmcDatalayer.splice(1, 0, ['setUserInfo', { email: cookies._sfe }]);
  }

  useEffect(() => {
    pushToGtmDatalayer({ pageType: 'HomePage' });
    promotionListTracking(cmsData);
  }, []);

  return { etmcDatalayer };
};
