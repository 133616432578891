import {
  useContext,
  useEffect,
  useState
} from 'react';

import { useStyles } from './ScrollUpArrow.style';

import { IconChevronUp } from '@/components/Icons';
import { ScrollContext } from '@/components/PartialWrapper';

export const ScrollUpArrow = (): React.ReactElement => {
  const [isActive, setIsActive] = useState(false);
  const scrollPosition = useContext(ScrollContext);
  const { classes, cx } = useStyles();

  const handleClick = (): void => window.scroll({ top: 0, left: 0, behavior: 'smooth' });

  useEffect(() => {
    if (!isActive && scrollPosition.y > 20) {
      setIsActive(true);
    }
    else if (isActive && scrollPosition.y < 20) {
      setIsActive(false);
    }
  }, [scrollPosition.y]);

  return (
    <button
      className={cx(classes.button, isActive && classes.active)}
      type="button"
      onClick={handleClick}
      title="Scroll up"
    >
      <IconChevronUp color="white" size={100} />
    </button>
  );
};
