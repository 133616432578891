import { type HomeProps } from './Home.types';

import { Container } from '@/components/Container';
import { PartialWrapper } from '@/components/PartialWrapper';
import { ScrollUpArrow } from '@/components/ScrollUpArrow';
import { Tracking } from '@/components/Tracking';
import { DynamicSections } from '@/features/DynamicSections';
import { EDRDollarsBanner } from '@/features/EDRDollarsBanner';
import { Header } from '@/features/Header';
import { Footer } from '@/features/NewFooter';
import { TrustpilotCarousel } from '@/features/TrustpilotCarousel';
import { useHomePageTracking } from '@/hooks/useHomePageTracking';

export const Home = ({
  cmsData,
  config,
  gtmDatalayer,
  footerData,
  headerData,
  userDetails
}: HomeProps): React.ReactElement => {
  const { etmcDatalayer } = useHomePageTracking(cmsData);

  return (
      <Tracking
        config={config}
        etmcDatalayer={etmcDatalayer}
        gtmDatalayer={gtmDatalayer}
        userDetails={userDetails}
      >
        <Header {...headerData} />
        <main>
          {userDetails && (
            <EDRDollarsBanner value={userDetails.edr.dollarBalance} />
          )}
          <ScrollUpArrow />
          <DynamicSections sectionsData={cmsData} />
          <Container size="lg">
            <TrustpilotCarousel businessId={config.TRUSTPILOT_BUSINESS_ID} />
          </Container>
        </main>
        <Footer {...footerData} />
      </Tracking>
  );
};

export const HomeWrapped = (props: HomeProps): React.ReactElement => (
  <PartialWrapper initialWishlistCount={props.headerData.wishlistCount}>
    <Home {...props} />
  </PartialWrapper>
);
