import { createStyles } from '@mantine/core';

export const useStyles = createStyles(theme => ({
  bannerWrapper: {
    backgroundColor: '#F6F5F1',
    color: theme.colors.orange[5],
    fontWeight: 600,
    transition: 'max-height 350ms ease-in-out',
    maxHeight: 0,
    overflow: 'hidden'
  },

  bannerWrapperActive: {
    maxHeight: '400px'
  },

  bannerContainer: {
    position: 'relative',
    padding: '0.7rem',

    [theme.fn.largerThan('sm')]: {
      padding: '1rem'
    }
  },

  icon: {
    marginRight: '1rem',
    height: '1.5rem',

    [theme.fn.largerThan('sm')]: {
      height: '1rem'
    }
  },

  text: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    paddingRight: '30px'
  },

  closeButton: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    padding: '1rem'
  }
}));
